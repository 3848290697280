import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  AccountRMResponse,
  AccountsAttachedToProfile,
  AccountTierResponse,
  AssignRmManagerResponse,
  AssignSignatory,
  AuditLog,
  CorporateResponse,
  IndividualAccountKycResponse,
  IndividualAccountProfiles,
  ProfilesAttachedToAccount,
  UpdateAccountInfo,
} from '@core/models/admin/account';
import { AccountKYC } from '@core/models/admin/account/account.interface';
import { Pagination } from '@core/models/pagination.model';
import { BehaviorSubject, Observable, finalize, shareReplay } from 'rxjs';
import { LoadingService } from './loading.service';
import { AccountListResponse } from '@core/models/admin/account/account-list.model';
import { GetAllRMsResponse } from '@core/models/admin/roles-and-permissions';
// This service handles account management for both the individidual and corporate accounts
@Injectable({
  providedIn: 'root',
})
export class AccountManagementService {
  private http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  private ADMIN_BASE_URL = '' + environment.ADMIN_BASE_URL + '/v1';
  private BASE_URL = environment.ADMIN_BASE_URL + '/v1/account';
  private ROLE_BASE_URL = environment.ADMIN_BASE_URL + '/v1/role';
  viewAccountDetails: any;
  accountDataSubject$ = new BehaviorSubject<AccountKYC | null>(null);
  accountKYCData$ = this.accountDataSubject$.asObservable();
  private corporateAccountInfoCache = new Map<
    string,
    {
      data$: Observable<CorporateResponse>;
      expiry: number;
    }
  >();
  private CACHE_DURATION = 5 * 60 * 1000;

  constructor() {}

  updateAccountInfo(accountInfo: UpdateAccountInfo) {
    return this.http.patch(this.BASE_URL, accountInfo);
  }
  createAccount(
    userId: string,
    accountType: string
  ): Observable<CreateAccountResponse> {
    return this.http.post<CreateAccountResponse>(
      `${this.BASE_URL}/create-individual-account`,
      {
        profile_id: userId,
        account_type: accountType,
      }
    );
  }

  setAccountKYCData(data: AccountKYC) {
    this.accountDataSubject$.next(data);
  }

  assignSignatory(signatoryInfo: AssignSignatory) {
    return this.http.post(this.BASE_URL, signatoryInfo);
  }

  getAccountsAttachedToProfile(accountId: AccountsAttachedToProfile) {
    this.http.post(this.BASE_URL, accountId);
  }

  getProfilesAttachedToAccount(userId: ProfilesAttachedToAccount) {
    this.http.post(this.BASE_URL, userId);
  }

  viewAccountInfo(accountId: string) {
    return this.http.get(
      `${this.BASE_URL}/get-individual-account-kyc?account_id=${accountId}`
    );
  }

  // Accounts Metadata
  getAccountListMetaData(): Observable<AccountMetaDataResponse> {
    return this.http.get<AccountMetaDataResponse>(
      `${this.BASE_URL}/get-account-meta`
    );
  }
  filterAccountList(queryParams: string): Observable<AccountListResponse> {
    return this.http.get<AccountListResponse>(
      `${this.BASE_URL}/filter-accounts-list?${queryParams}`
    );
  }
  viewAccountList(
    data: Pagination = new Pagination()
  ): Observable<AccountListResponse> {
    const uniqueStateUrl = 'get-account-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    const paginationInstance = new Pagination(data);
    const params = new HttpParams()
      .set('take', paginationInstance.size.toString())
      .set('skip', paginationInstance.skip.toString());

    return this.http
      .get<AccountListResponse>(`${this.BASE_URL}/get-account-list`, { params })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  //Audit
  viewAuditInfo(auditId: string) {
    return this.http.get(`${this.BASE_URL}/view-audit-info/${auditId}`);
  }

  viewAuditList(data: Pagination = new Pagination()): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/view-audit-list?take=${data.size}&order=${data.order}&skip=${data.skip}`
    );
  }

  filterAccountAuditLogs(
    queryParams: string,
    accountId: string
  ): Observable<AuditLog> {
    return this.http.get<AuditLog>(
      `${this.BASE_URL}/filter-audit-log-by-account?account_id=${accountId}&${queryParams}`
    );
  }

  getAccountAuditLogs(
    accountId: string,
    data: Pagination = new Pagination()
  ): Observable<AuditLog> {
    return this.http.get<AuditLog>(
      `${this.BASE_URL}/get-account-audit-log?account_id=${accountId}&take=${data.size}&skip=${data.skip}`
    );
  }

  //Portfolio
  viewPortfolioInfo(portfolioId: string) {
    return this.http.get(`${this.BASE_URL}/view-portfolio-info/${portfolioId}`);
  }
  viewPortfolioList(data: Pagination = new Pagination()): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/view-portfolio-list?take=${data.size}&order=${data.order}&skip=${data.skip}`
    );
  }

  //Wallet
  viewWalletInfo(walletId: string) {
    return this.http.get(`${this.BASE_URL}/view-wallet-info/${walletId}`);
  }
  viewWalletList(data: Pagination = new Pagination()): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/view-wallet-list?take=${data.size}&order=${data.order}&skip=${data.skip}`
    );
  }

  getIndividualAccountInfo(
    accountId: string
  ): Observable<IndividualAccountKycResponse> {
    const uniqueStateUrl = 'get-individual-account-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<IndividualAccountKycResponse>(
        `${this.BASE_URL}/get-individual-account-kyc?account_id=${accountId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getCorporateAccountInfo(accountId: string): Observable<CorporateResponse> {
    const uniqueStateUrl = 'get-corporate-account-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);

    const cacheEntry = this.corporateAccountInfoCache.get(accountId);

    if (cacheEntry && Date.now() < cacheEntry.expiry) {
      this.loadingService.setLoading(uniqueStateUrl, false);
      return cacheEntry.data$;
    }

    const accountInfo$ = this.http
      .get<CorporateResponse>(
        `${this.BASE_URL}/get-corporate-account-kyc?account_id=${accountId}`
      )
      .pipe(
        shareReplay(1),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );

    this.corporateAccountInfoCache.set(accountId, {
      data$: accountInfo$,
      expiry: Date.now() + this.CACHE_DURATION,
    });

    return accountInfo$;
  }

  clearCache(accountId: string) {
    this.corporateAccountInfoCache.delete(accountId);
  }

  clearAllCache() {
    this.corporateAccountInfoCache.clear();
  }

  searchAccount(searchText: string) {
    return this.http.get(
      `${this.BASE_URL}/search-accounts?search_text=${searchText}`
    );
  }

  exportIndividualKyc(accountId: string): Observable<ExportKycResponse> {
    return this.http.get<ExportKycResponse>(
      `${this.ADMIN_BASE_URL}/kyc/export-individual-kyc-data?account_id=${accountId}`
    );
  }

  exportCorporateKyc(accountId: string): Observable<ExportKycResponse> {
    return this.http.get<ExportKycResponse>(
      `${this.ADMIN_BASE_URL}/kyc/export-corporate-kyc-data?account_id=${accountId}`
    );
  }

  exportJointKyc(accountId: string): Observable<ExportKycResponse> {
    return this.http.get<ExportKycResponse>(
      `${this.ADMIN_BASE_URL}/kyc/export-joint-kyc-data?account_id=${accountId}`
    );
  }

  getAccountProfiles(accountId: string): Observable<IndividualAccountProfiles> {
    const uniqueStateUrl = 'get-profile-accounts';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post<IndividualAccountProfiles>(
        `${this.BASE_URL}/get-profile-accounts`,
        {
          user_identifier: accountId,
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getAccountKycTier(accountId: string): Observable<AccountTierResponse> {
    const uniqueStateUrl = 'check-kyc-tier-level';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<AccountTierResponse>(
        `${this.BASE_URL}/check-kyc-tier-level?account_id=${accountId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getAccountRelationManager(accountId: string): Observable<AccountRMResponse> {
    const uniqueStateUrl = 'get-account-relation-manager';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<AccountRMResponse>(
        `${this.BASE_URL}/get-account-relation-manager?account_id=${accountId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getAllRMs(): Observable<GetAllRMsResponse> {
    const uniqueStateUrl = 'get-all-relationship-managers';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<GetAllRMsResponse>(`${this.ROLE_BASE_URL}/get-relationship-manager`)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  assignRelationshipManager(payload: {
    admin_id: string;
    account_id: string;
  }): Observable<AssignRmManagerResponse> {
    const uniqueStateUrl = 'assign-relationship-manager';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post<AssignRmManagerResponse>(
        `${this.BASE_URL}/assign-relationship-manager`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}

interface CreateAccountResponse {
  status: string;
  message: string;
  data: CreateAccountResponseData;
}

interface CreateAccountResponseData {
  id: string;
  account_type: string;
  mango_account_number: string;
  virtual_account_number?: any;
  virtual_account_name?: any;
  admin_user_id?: any;
  user_id: string;
  updated_at: string;
  created_at: string;
}

interface ExportKycResponse {
  status: string;
  message: string;
  data: Data;
}

interface Data {
  statement: string;
  result: Result;
}

interface Result {
  $metadata: Metadata;
  ETag: string;
  ServerSideEncryption: string;
}

interface Metadata {
  httpStatusCode: number;
  requestId: string;
  extendedRequestId: string;
  attempts: number;
  totalRetryDelay: number;
}

interface AccountMetaDataResponse {
  status: string;
  message: string;
  data: Data;
}
